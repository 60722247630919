import * as actionTypes from "../actions/actionType";

const initialState = {
  user: [],
  token: "",
  toggleChatCoach: false,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
 
    case actionTypes.USER_LOGIN:
      return {
        ...state,
        user: action.payload,
        token: action.payload.token
      };
    case actionTypes.USER_LOGOUT:
      return {
        ...state,
        user: null,
        token:null
      };

 
   
    default:
      return state;
  }
};
