import React, { useEffect, useState } from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { sendInvitePeople } from "../../redux/actions/authAction";
import { ToastContainer } from "react-toastify";
import { toastify } from "../../components/Toast";

const InviteUser = () => {
  const dispatch = useDispatch();
  const { allOrganizations } = useSelector(
    (state) => state.getOrganizationData
  );
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const [selectedOrganizationState, setSelectedOrganizationState] =
    useState("");
  const onSendInviteHandler = () => {
    setLoader(true);
    if (email) {
      const inviteUserData = {
        email: email,
        organizationId: selectedOrganizationState,
      };
      dispatch(
        sendInvitePeople(inviteUserData, callBackForInviteUser, setLoader)
      );
    } else {
      toastify("error", "Please enter email");
      setLoader(false);
    }
  };
  const callBackForInviteUser = (res) => {
    if (res) {
      setEmail("");
      setSelectedOrganizationState("");
    }
  };
  return (
    <>
      <>
        <ToastContainer />
      </>
      <div className="invite-user">
        <div className="row mb-3 pt-5 pl-5">
          <div className="col-md-6">
            <h1 className="healthKitUpdateHeaderFont d-flex">Invite Users</h1>
          </div>
          <div className="col-md-6 d-flex justify-content-end"></div>
        </div>
        <div className="invite-user-container">
          <Card className="pt-4 pr-4 pl-4 pb-2 m-5">
            <>
              <Form>
                <Form.Group
                  className="mb-3 organization-container-with-email"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>
                    <h4 className="d-flex font-weight-bold">
                      Select organization
                    </h4>
                  </Form.Label>

                  <>
                    <form className="goalSelectorFieldForm">
                      <select
                        className="goalSelectorField"
                        onChange={(event) =>
                          setSelectedOrganizationState(event.target.value)
                        }
                        value={selectedOrganizationState}
                      >
                        <option
                          value=""
                          disabled
                          selected
                          className="first-options"
                        >
                          Select your organization
                        </option>
                        {allOrganizations?.data?.map((organization, index) => {
                          return (
                            <>
                              <option value={organization?._id}>
                                {organization?.organizationName}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </form>
                  </>
                </Form.Group>
              </Form>
            </>
            <h4 className="d-flex font-weight-bold"> Add email</h4>
            <div className="add-email-input mt-3">
              <input
                type="email"
                value={email}
                className="form-control"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="send-invite-btn mt-3 mb-2">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={onSendInviteHandler}
                disabled={loader}
              >
                {loader ? (
                  <Spinner
                    className=""
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "  Send"
                )}
              </button>
            </div>
            {/* <Card.Body className=""></Card.Body> */}
          </Card>
        </div>
      </div>
    </>
  );
};

export default InviteUser;
