import React, { useEffect, useMemo, useState } from "react";
import {
  Calendar,
  dateFnsLocalizer,
  momentLocalizer,
  Views,
} from "react-big-calendar";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import DatePicker from "react-date-picker";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toastify } from "../../components/Toast";
import { ToastContainer } from "react-toastify";

import {
  createActivity,
  deleteActivity,
  getselectedUserActivities,
  getSelectedActivityDataOfUser,
  getUserGoals,
  updateActivity,
  getJourneysData,
} from "../../redux/actions/authAction";
import moment from "moment";
import "moment-timezone";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import ImageCompress from "quill-image-compress";
import { Oval } from "react-loader-spinner";
import { MenuItem, Select } from "@mui/material";
const ManageActivitiesSelected = () => {
  const [allEvents, setAllEvents] = useState([]);
  const [titleState, setTitleState] = useState("");
  const [descriptionState, setDescriptionState] = useState("");
  const [goalState, setGoalState] = useState("");
  const [show, setShow] = useState(false);
  const [arrayOfGoals, setArrayOfGoals] = useState([]);
  const [startDateState, setStartDateState] = useState(new Date());
  const [loaderForCreateActivity, setLoaderForCreateActivity] = useState(false);
  const [selectedActivityId, setSelectedActivityId] = useState();
  const [loaderForActivity, setLoaderForActivity] = useState(false);
  const [loaderForUpdateActivity, setLoaderForUpdateActivity] = useState(false);
  const [loaderForDeleteActivity, setLoaderForDeleteActivity] = useState(false);
  const [activeAttempt, setActiveAttempt] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedJourneyDate, setSelectedJourneyDate] = useState(null);
  const [modalForJourneyInfoOfUser, setModalForJourneyInfoOfUser] =
    useState(false);
  const [loadingForJourneyInfo, setLoadingForJourneyInfo] = useState(false);
  // edit
  const [editActivityModal, setEditActivityModal] = useState(false);
  const [editTitleState, setEditTitleState] = useState("");
  const [editDescriptionState, setEditDescriptionState] = useState("");
  const [editGoalState, setEditGoalState] = useState("");
  const [editGoalFirstState, setEditGoalFirstState] = useState("");
  const [editStartDateState, setEditStartDateState] = useState("");
  const [editEndDateState, setEditEndDateState] = useState("");

  const dispatch = useDispatch();
  const location = useLocation();
  const [clientInfo] = useState(location?.state?.e);
  const { allActivities, selectedUserGoals, selectedActivityDataOfUser } =
    useSelector((state) => state?.getManageActivityData);
  const { journeysData } = useSelector((state) => state.allDashboardData);
  useEffect(() => {
    setEditTitleState(selectedActivityDataOfUser?.data?.activity?.title);
    setEditDescriptionState(
      selectedActivityDataOfUser?.data?.activity?.description
    );
    setEditGoalFirstState(
      selectedActivityDataOfUser?.data?.activity?.goalId?.title
    );
  }, [selectedActivityDataOfUser]);

  useEffect(() => {
    if (journeysData?.data && selectedJourneyDate) {
      const attempt = journeysData.data.find(
        (journey) => journey.attempt === selectedJourneyDate
      );

      if (attempt) {
        setSelectedJourneyDate(attempt);
      }
    }
  }, [selectedJourneyDate, journeysData]);

  useEffect(() => {
    if (clientInfo?._id) {
      dispatch(getJourneysData(callBackForJourneyData, clientInfo?._id));
      setSelectedUser(clientInfo);
      setLoaderForActivity(true);
      dispatch(getUserGoals(clientInfo?._id));
    }
  }, [clientInfo]);

  const callBackForJourneyData = (res) => {};
  useEffect(() => {
    const Attempt = journeysData?.data?.find(
      (journey) => journey.isActive === true
    );
    setActiveAttempt(Attempt);

    // Check if Attempt is found and has an attempt property
    if (Attempt?.attempt) {
      dispatch(
        getselectedUserActivities(
          selectedUser?._id,
          Attempt.attempt,
          callBackForActivities,
          setSelectedJourneyDate
        )
      );
      dispatch(getUserGoals(selectedUser?._id));
    } else {
      setLoaderForActivity(false);
    }
  }, [journeysData?.data]);
  useEffect(() => {
    if (activeAttempt?.attempt) {
      setSelectedValue(activeAttempt.attempt);
    }
  }, [activeAttempt]);

  const callBackForActivities = (response) => {
    setLoaderForActivity(false);
    const eventsData = response?.data?.map((item) => {
      item.start = new Date(item.startDate);
      item.end = new Date(item.endDate);

      return item;
    });
    setAllEvents(eventsData);
  };
  moment.tz.setDefault("Europe/London");
  const localizer = momentLocalizer(moment);
  const { views } = useMemo(
    () => ({
      views: [Views.MONTH],
    }),
    []
  );

  const onAddEventBtn = () => {
    if (selectedUserGoals?.data?.length) {
      handleShow();
    } else {
      toastify("error", "User don't have any selected goals");
    }

    setArrayOfGoals(selectedUserGoals);
  };
  const handleClose = () => {
    setShow(false);
    setDescriptionState("");
  };
  const onSaveEventData = () => {
    setLoaderForCreateActivity(true);
    const startDate = new Date(startDateState);
    const isoStartDate = new Date(
      startDate.getTime() - startDate.getTimezoneOffset() * 60000
    ).toISOString();

    const userData = {
      userId: clientInfo?._id,
      endDate: isoStartDate,
      startDate: isoStartDate,
      title: titleState,
      description: descriptionState,
      goalId: goalState,
    };
    if (userData.title !== "") {
      dispatch(
        createActivity(
          userData,
          setLoaderForCreateActivity,
          callbackForNewActivity,
          userData?.userId
        )
      );
    } else {
      toastify("error", "Please add title,its compulsory");
    }
  };
  const callbackForNewActivity = (res, userId) => {
    setShow(false);
    setLoaderForActivity(true);
    dispatch(
      getselectedUserActivities(
        selectedUser?._id,
        selectedValue,
        callBackForActivities,
        setSelectedJourneyDate
      )
    );
    setTitleState("");
    setDescriptionState("");
    setGoalState("");
    setStartDateState(new Date());
  };
  const handleShow = () => {
    setShow(true);
  };
  const handleSelectEvent = (event, e) => {
    dispatch(
      getSelectedActivityDataOfUser(event?._id, callBackForSelectedUserActivity)
    );
    setSelectedActivityId(event?._id);
    setArrayOfGoals(selectedUserGoals);
    setEditStartDateState(event?.start);
    setEditEndDateState(event?.end);
  };
  const callBackForSelectedUserActivity = (res) => {
    if (res) {
      setEditActivityModal(true);
    }
  };
  const onEditActivityModalClose = () => {
    setEditActivityModal(false);
    setEditGoalState("");
    setEditDescriptionState("");
  };
  const onUpdateActivityHandler = () => {
    const updateActivityObj = {
      title: editTitleState,
      goalId:
        editGoalState === ""
          ? selectedActivityDataOfUser?.data?.activity?.goalId?._id
          : editGoalState,
      userId: clientInfo?._id,
      description: editDescriptionState,
      startDate: editStartDateState,
      endDate: editEndDateState,
    };
    dispatch(
      updateActivity(
        selectedActivityId,
        updateActivityObj,
        callBackForUpdateActivity
      )
    );
    setLoaderForUpdateActivity(true);
  };
  const callBackForUpdateActivity = () => {
    setEditActivityModal(false);
    setLoaderForUpdateActivity(false);
    setLoaderForActivity(true);
    dispatch(
      getselectedUserActivities(
        selectedUser?._id,
        selectedValue,
        callBackForActivities,
        setSelectedJourneyDate
      )
    );
  };
  const onDeleteActivityHandler = () => {
    dispatch(deleteActivity(selectedActivityId, callBackForDeleteActivity));
    setLoaderForDeleteActivity(true);
  };
  const callBackForDeleteActivity = () => {
    setEditActivityModal(false);
    setLoaderForDeleteActivity(false);
    setLoaderForActivity(true);
    dispatch(
      getselectedUserActivities(
        selectedUser?._id,
        selectedValue,
        callBackForActivities,
        setSelectedJourneyDate
      )
    );
  };
  //React Quill
  Quill.register("modules/ImageResize", ImageResize);
  Quill.register("modules/imageCompress", ImageCompress);
  const editorModules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],

      ["bold", "italic", "underline", "strike"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
    ],
    ImageResize: {
      parchment: Quill.import("parchment"),
      handleStyles: {
        displaySize: true,
        backgroundColor: "black",
        border: "none",
        color: "white",
      },
      modules: ["Resize", "DisplaySize"],
    },
    imageCompress: {
      quality: 0.7, // default
      maxWidth: 700,
      maxHeight: 700,
      imageType: ["image/jpeg", "image/png", "image/jpg"], // default
      debug: true, // default
    },
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const editorFormats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  // const options = journeysData?.data
  //   ?.filter((attempt) => attempt.isJourneyStarted)
  //   .map((attempt) => ({
  //     value: attempt.attempt,
  //     label: `Journey ${attempt.attempt}`,
  //   }));
  const options = journeysData?.data?.map((attempt) => ({
    value: attempt.attempt,
    label: `Journey ${attempt.attempt}`,
  }));
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    dispatch(
      getselectedUserActivities(
        selectedUser?._id,
        event.target.value,
        callBackForActivities,
        setSelectedJourneyDate
      )
    );
    setLoaderForActivity(true);
  };
  const onJourneyInfoBtn = () => {
    setModalForJourneyInfoOfUser(true);
  };
  const onJourneyInfoModalClose = () => {
    setModalForJourneyInfoOfUser(false);
  };
  return (
    <>
      <>
        {" "}
        <ToastContainer />
      </>
      {/* ADD ACTIVITY MODAL */}
      <>
        <Modal
          backdrop="static"
          keyboard={false}
          data-dismiss="modal"
          show={show}
          onHide={handleClose}
          centered
        >
          <Modal.Header
            className="modal-header-workout"
            closeButton={!loaderForCreateActivity}
          >
            <Modal.Title>Add an activity</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="write a title for your activity"
                    onChange={(e) => setTitleState(e.target.value)}
                    value={titleState}
                  />
                </Form.Group>{" "}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Description</Form.Label>

                  <ReactQuill
                    theme="snow"
                    value={descriptionState}
                    onChange={setDescriptionState}
                    modules={editorModules}
                    formats={editorFormats}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Select goals</Form.Label>

                  <>
                    <form className="goalSelectorFieldForm">
                      <select
                        className="goalSelectorField"
                        onChange={(event) => setGoalState(event.target.value)}
                        value={goalState}
                      >
                        <option hidden className="first-options">
                          Select your goals
                        </option>
                        {arrayOfGoals?.data?.map((ele) => {
                          return (
                            <>
                              <option value={ele?._id}>{ele?.title}</option>
                            </>
                          );
                        })}
                      </select>
                    </form>
                  </>
                </Form.Group>
                <Form.Label>Date</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <DatePicker
                    onChange={setStartDateState}
                    value={startDateState}
                  />
                </Form.Group>
                {/* <Form.Label>End date</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                 
                  <DatePicker onChange={setEndDateState} value={endDateState} />
                </Form.Group> */}
              </Form>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleClose}
              disabled={loaderForCreateActivity}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={onSaveEventData}
              disabled={loaderForCreateActivity}
            >
              {loaderForCreateActivity ? "Saving.." : "Save"}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
      {/* EDIT ACTIVITY */}
      <>
        <Modal
          backdrop="static"
          keyboard={false}
          data-dismiss="modal"
          show={editActivityModal}
          onHide={onEditActivityModalClose}
          centered
        >
          <Modal.Header className="modal-header-workout" closeButton>
            <Modal.Title>Edit an activity</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="write a title for your activity"
                    onChange={(e) => setEditTitleState(e.target.value)}
                    value={editTitleState}
                  />
                </Form.Group>{" "}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Description</Form.Label>
                  <ReactQuill
                    theme="snow"
                    value={editDescriptionState}
                    onChange={setEditDescriptionState}
                    modules={editorModules}
                    formats={editorFormats}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <div className="goal-for-plan mb-3">
                    <Form.Label>Goal</Form.Label>
                    <Form.Select
                      onChange={(e) => setEditGoalState(e.target.value)}
                      value={editGoalState}
                    >
                      <option hidden>{editGoalFirstState}</option>
                      {selectedUserGoals?.data?.map((goal, index) => {
                        return <option value={goal?._id}>{goal?.title}</option>;
                      })}
                    </Form.Select>
                  </div>
                </Form.Group>
              </Form>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onEditActivityModalClose}>
              Cancel
            </Button>
            <Button
              disabled={loaderForDeleteActivity}
              variant="danger"
              onClick={onDeleteActivityHandler}
            >
              {loaderForDeleteActivity ? "Deleting" : "Delete"}
            </Button>
            <Button
              disabled={loaderForUpdateActivity}
              variant="primary"
              onClick={onUpdateActivityHandler}
            >
              {loaderForUpdateActivity ? "Saving" : "Save"}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
      <div>
        <div className="row">
          <div className="chat-and-event-btn">
            <div className="add-event-btn">
              <div className="activity-journey-dropdown">
                <div>
                  <Select value={selectedValue} onChange={handleChange}>
                    {options?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="activity-journey-dates">
                  {selectedJourneyDate?.startDate ? (
                    <>
                      <div>
                        Start date :{" "}
                        {moment(selectedJourneyDate?.startDate).format(
                          "MMM Do YY"
                        )}
                      </div>
                      <div>
                        End date :{" "}
                        {moment(selectedJourneyDate?.endDate).format(
                          "MMM Do YY"
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>{" "}
              <div className="activity-btn-container">
                <Button className="btn-srvc mr-4" onClick={onJourneyInfoBtn}>
                  Journeys info
                </Button>
                <Button className="btn-srvc" onClick={onAddEventBtn}>
                  Add an Activity
                </Button>
              </div>
            </div>
          </div>
        </div>
        {loaderForActivity ? (
          <div className="loader-container " style={{ margin: "5% 0 5% 0" }}>
            <Oval
              height={30}
              width={30}
              color="#1b46b4"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#56bcb2"
              strokeWidth={6}
              strokeWidthSecondary={6}
            />
          </div>
        ) : (
          <div className="row selected-user-name">
            <h2>
              {clientInfo?.firstName +
                " " +
                clientInfo?.lastName +
                "'s activities"}
            </h2>
            {allEvents?.length ? (
              <div className="calendar">
                <Calendar
                  localizer={localizer}
                  events={allEvents}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: "60vh", margin: "30px" }}
                  onSelectEvent={(e) => handleSelectEvent(e)}
                  eventPropGetter={(event) => {
                    const today = new Date();
                    // Check if the event is in the past
                    if (event.start < today) {
                      if (event.isCompleted) {
                        // Completed past activity - set background color to green
                        return {
                          style: {
                            backgroundColor: "#17AD76",
                            color: "white",
                          },
                        };
                      } else {
                        // Incomplete past activity - set background color to red
                        return {
                          style: {
                            backgroundColor: "#DB7D76",
                            color: "white",
                          },
                        };
                      }
                    }
                    // Default for future or ongoing events
                    return {};
                  }}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center", // Center vertically
                  justifyContent: "center", // Center horizontally
                  height: "50vh", // Take full viewport height
                  margin: "0", // Remove margin
                }}
              >
                Journey is not started yet
              </div>
            )}
          </div>
        )}
      </div>
      <>
        {/*User Engagement from Journey modal*/}
        <Modal
          size="lg"
          backdrop="static"
          className="p-0"
          keyboard={false}
          data-dismiss="modal"
          show={modalForJourneyInfoOfUser}
          onHide={onJourneyInfoModalClose}
          centered
        >
          <Modal.Header className="modal-header-workout" closeButton>
            <Modal.Title>
              Journey's info of {selectedUser?.firstName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="add-question-modal-body">
            {loadingForJourneyInfo ? (
              <div className="loader-container">
                <Oval
                  height={30}
                  width={30}
                  color="#1b46b4"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#56bcb2"
                  strokeWidth={6}
                  strokeWidthSecondary={6}
                />
              </div>
            ) : (
              <div>
                {journeysData?.data?.map((journey, index) => (
                  <div key={index} className="journey-container">
                    <div className="journeys-heading">
                      Journey {journey?.attempt}
                    </div>

                    <div className="Journeys-dates-container">
                      {journey?.startDate ? (
                        <>
                          {" "}
                          <div className="Journeys-dates-param">
                            <p>Start date</p>
                            <p>End date</p>
                          </div>
                          <div className="Journeys-dates-data">
                            <p>
                              {moment(journey?.startDate).format("MMM Do YY")}
                            </p>

                            <p>
                              {moment(journey?.endDate).format("MMM Do YY")}
                            </p>
                          </div>
                        </>
                      ) : (
                        <div className="Journeys-dates-data">
                          {" "}
                          Journey is not started yet
                        </div>
                      )}{" "}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </Modal.Body>
        </Modal>
      </>
    </>
  );
};

export default ManageActivitiesSelected;
