import * as actionTypes from "../actions/actionType";

const initialState = {
  allPlans: [],
  filterPlans: [],
  allFreePlans: [],
  deletePlan: {},
  addPlan: [],
  updatePlan: {},
  getPlan: {},
};

export const managePlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ALL_PLANS:
      return {
        ...state,
        allPlans: action.payload,
      };

    case actionTypes.FILTERED_PLANS:
      return {
        ...state,
        filterPlans: action.payload,
      };

    case actionTypes.ALL_FREE_PLANS:
      return {
        ...state,
        allFreePlans: action.payload,
      };
    case actionTypes.DELETE_PLAN:
      return {
        ...state,
        deletePlan: action.payload,
      };
    case actionTypes.ADD_PLAN:
      return {
        ...state,
        addPlan: action.payload,
      };
    case actionTypes.GET_PLAN:
      return {
        ...state,
        getPlan: action.payload,
      };
    case actionTypes.UPDATE_PLAN:
      return {
        ...state,
        updatePlan: action.payload,
      };

    default:
      return state;
  }
};
