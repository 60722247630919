import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { getFeedbacks } from "../../redux/actions/authAction";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";
import { Oval } from "react-loader-spinner";

const Feedbacks = () => {
  const dispatch = useDispatch();
  const { feedbacks } = useSelector((state) => state.getManageUsersData);
  const [selectedFeedback, setSelectedFeedback] = useState("");
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    dispatch(getFeedbacks(callBackForFeedback));
    setLoader(true);
  }, [dispatch]);
  const callBackForFeedback = (res) => {
    if (res) return setLoader(false);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onClientWholeRow = (data) => {
    setSelectedFeedback(data);
    handleShow();
  };
  const formatDate = (dateString) => {
    const createdAt = new Date(dateString);
    
    const optionsDate = { year: 'numeric', month: 'short', day: 'numeric' };
    const optionsTime = { hour: 'numeric', minute: 'numeric', hour12: true };
  
    return `${createdAt.toLocaleDateString('en-US', optionsDate)} & ${createdAt.toLocaleTimeString('en-US', optionsTime)}`;
  };
  return (
    <>
      <ToastContainer />
      <div className="health-kit py-5 px-5">
        <div className="row mb-3">
          <div className="col-md-6">
            <h1 className="healthKitUpdateHeaderFont d-flex ">Feedbacks</h1>
          </div>
          <div className="col-md-6 d-flex justify-content-end"></div>
        </div>

        <div className="row">
          <div className="coach-clients-page">
            <div className="clients-page-main-container">
              {loader ? (
                <div className="loader-container">
                  {" "}
                  <Oval
                    height={40}
                    width={120}
                    color="#1b46b4"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#56bcb2"
                    strokeWidth={6}
                    strokeWidthSecondary={6}
                  />
                </div>
              ) : (
                <>
                  {" "}
                  {feedbacks?.data?.length ? (
                    <table>
                      <thead>
                        <tr
                          className="header-row"
                          style={{ margin: "10px", height: "50px" }}
                        >
                          <th
                            style={{
                              width: "15%",
                              paddingLeft: "6%",
                              textAlign: "left",
                            }}
                          >
                            Name
                          </th>
                          <th style={{ width: "15%" }}>Date & Time</th>
                          <th style={{ width: "10%" }}>Role</th>
                          <th style={{ width: "20%" }}>Email</th>
                          <th style={{ width: "40%" }}>Feedback</th>
                          <th
                            style={{
                              borderTopRightRadius: "8px",
                              borderBottomRightRadius: "8px",
                            }}
                          ></th>
                        </tr>
                      </thead>

                      <tbody>
                        {feedbacks?.data?.map((data, index) => {
                          return (
                            <tr
                              className="whole-row-manage-activity-user-list"
                              key={index}
                              style={{ borderBottom: "1px solid #f1f5f9" }}
                              onClick={() => onClientWholeRow(data)}
                            >
                              <td className="pic-name-in-table">
                                <div className="client-img-in-table ms-3 pt-1">
                                  {data?.userId?.imageUrl ? (
                                    <img
                                      src={data?.userId?.imageUrl}
                                      className="coach-clients-data-img"
                                      alt="User"
                                    />
                                  ) : (
                                    <img
                                      src="/img/sample_img_2.png"
                                      className="coach-clients-data-img"
                                      alt="User"
                                    />
                                  )}
                                </div>
                                <div
                                  style={{
                                    paddingTop: "15px",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {data?.userId?.firstName}{" "}
                                  {data?.userId?.lastName}
                                </div>
                              </td>
                              <td
                                style={{
                                  paddingLeft: "12px",
                                  color: "#1b46b4",
                                  width: "160px",
                                  wordBreak: "break-word",
                                }}
                              >
                                {formatDate(data?.createdAt)}
                              </td>
                              <td>
                                <div
                                  className="d-grid gap-2 d-md-block"
                                  style={{ wordBreak: "break-word" }}
                                >
                                  {data?.userId?.isCoach ? "Coach" : "User"}
                                </div>
                              </td>
                              <td
                                style={{
                                  paddingLeft: "12px",
                                  color: "#1b46b4",
                                  width: "160px",
                                  wordBreak: "break-word",
                                }}
                              >
                                {data?.userId?.email}
                              </td>
                              <td className="feedback-message">
                                {data?.message}
                              </td>
                              <td style={{ width: "133px" }}></td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <div className="No-data-statement">
                      <h3>No feedback yet</h3>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedFeedback?.userId?.firstName}'s feedback
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ maxHeight: "400px", overflowY: "auto" }}>
            <div
              className="feedback-details"
              style={{
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                columnGap: "20px",
                rowGap: "12px",
              }}
            >
              <div>
                <strong>Data & Time:</strong>
              </div>
              <div>{moment(selectedFeedback?.createdAt).format("LLL")}</div>
              <div>
                <strong>Name:</strong>
              </div>
              <div>
                {selectedFeedback?.userId?.firstName}{" "}
                {selectedFeedback?.userId?.lastName}
              </div>
              <div>
                <strong>Email:</strong>
              </div>
              <div>{selectedFeedback?.userId?.email}</div>
              <div>
                <strong>Role:</strong>
              </div>
              <div>
                {selectedFeedback?.userId?.isCoach === false ? "User" : "Coach"}
              </div>
              <div>
                <strong>Feedback:</strong>
              </div>
              <div style={{ paddingRight: "50px" }}>
                {selectedFeedback?.message}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Feedbacks;
