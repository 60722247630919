import React from 'react'

const Navbar = () => {
  return (
    <>
      <div className="nav-top py-2 px-3 navbar-main-container">
        <div className="row ">
          <div className="col-md-6 d-flex ">
          <h4 className="font-weight-bold mt-3 navbar-welcome-name">
                Welcome Admin
              </h4>
           
          </div>
          {/* <div className="col-md-6 d-flex justify-content-end px-5">
            <div className="bell">
              <img
                src="/img/bell-2.png"
                className="mt-3 mr-3"
                alt="img"
              />
            </div>

            <div className="user-img-navbar ms-3">
            
                <img className="image" src="/img/sample_img_2.png" alt="img" />
             
            </div>
          
          </div> */}
        </div>
      </div>
      </>
  )
}

export default Navbar