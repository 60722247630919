import * as actionTypes from "../actions/actionType";

const initialState = {
  allCoaches: [],
  token: "",
  blockedCoachInReducer: "",
  blockCoachesList: [],
  unBlockCoach: "",
  addCoach: {},
  updateCoach: {},
};

export const manageCoachReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_COACHES:
      return {
        ...state,
        allCoaches: action.payload,
        token: action.payload.token,
      };
    case actionTypes.BLOCK_COACH:
      return {
        ...state,
        blockedCoachInReducer: action.payload,
      };
    case actionTypes.BLOCK_COACHES_LIST:
      return {
        ...state,
        blockCoachesList: action.payload,
      };
    case actionTypes.UNBLOCK_COACH:
      return {
        ...state,
        unBlockCoach: action.payload,
      };
    case actionTypes.ADD_COACH:
      return {
        ...state,
        addCoach: action.payload,
      };
    case actionTypes.UPDATE_COACH:
      return {
        ...state,
        updateCoach: action.payload,
      };
    default:
      return state;
  }
};
