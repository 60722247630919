import * as actionTypes from "../actions/actionType";

const initialState = {
  allUsers: [],
  surveyResultOfSingleUser: [],
  feedbacks: [],
  filteredUsers: [],
  token: "",
  toggleChatCoach: false,
  blockedUserInReducer: {},
  blockUsersList: [],
  unBlockUser: {},
  addUserInRedux: {},
  updateUserInRedux: {},
};

export const manageUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_USERS:
      return {
        ...state,
        allUsers: action.payload,
        token: action.payload.token,
      };

    case actionTypes.GET_FILTERED_USERS:
      return {
        ...state,
        filteredUsers: action.payload,
        // token: action.payload.token,
      };
    case actionTypes.BLOCK_USER:
      return {
        ...state,
        blockedUserInReducer: action.payload,
      };
    case actionTypes.BLOCK_USERS_LIST:
      return {
        ...state,
        blockUsersList: action.payload,
      };
    case actionTypes.UNBLOCK_USER:
      return {
        ...state,
        unBlockUser: action.payload,
      };
    case actionTypes.ADD_USER:
      return {
        ...state,
        addUserInRedux: action.payload,
      };
    case actionTypes.UPDATE_USER:
      return {
        ...state,
        updateUserInRedux: action.payload,
      };
    case actionTypes.SURVEY_RESULT:
      return {
        ...state,
        surveyResultOfSingleUser: action.payload,
      };
    case actionTypes.GET_FEEDBACKS:
      return {
        ...state,
        feedbacks: action.payload,
      };
    default:
      return state;
  }
};
