import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { dashboardReducer } from "./dashboardReducer";
import { manageActivityReducer } from "./manageActivitiesReducer";
import { manageCoachReducer } from "./manageCoachReducer";
import { manageOrganizationReducer } from "./manageOrganizationReducer";
import { managePlanReducer } from "./managePlanReducer";
import { manageQuestionReducer } from "./manageQuestionReducer";
import { manageUploadContentReducer } from "./manageUploadContentReducer";
import { manageUserReducer } from "./manageUsersReducer";
import { reportsReducer } from "./reportsReducer";

const appReducer = combineReducers({
  authentication: authReducer,
  allDashboardData: dashboardReducer,
  getManageUsersData: manageUserReducer,
  getManageCoachesData: manageCoachReducer,
  getManageActivityData: manageActivityReducer,
  getQuestionData: manageQuestionReducer,
  getOrganizationData: manageOrganizationReducer,
  getUploadContentData: manageUploadContentReducer,
  getPlansdata: managePlanReducer,
  getReportsData: reportsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
