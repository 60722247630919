import React, { useEffect, useState } from "react";
import { Button, Dropdown, NavLink } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getMatrixOfQuestion } from "../redux/actions/authAction";
import { ToastContainer } from "react-toastify";
import { toastify } from "../components/Toast";
const AddValue = (props) => {
  const [value, setValue] = useState("");
  const [score, setScore] = useState("");
  const [optionsAndScores, setOptionsAndScores] = useState([]);
  const [allQuestionDataForMatrix, setAllQuestionDataForMatrix] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    setAllQuestionDataForMatrix(data);
  }, [optionsAndScores]);

  const onSaveValueAndScore = () => {
    if (score && value) {
      setOptionsAndScores([
        ...optionsAndScores,
        { value, score, id: (Math.random() + 1).toString(36).substring(7) },
      ]);
      setScore("");
      setValue("");
    }
  };
  const data = {
    questionId: props.selectedParentQuestion,
    options: {
      optionType: props.selectedOption,
      values: optionsAndScores,
    },
  };
  props.setOptionsForNewQuestion(optionsAndScores);

  const onOptionClick = (id) => {
    const newArr = optionsAndScores.filter((item) => item.id !== id);
    setOptionsAndScores(newArr);
  };
  const onSaveMatrixData = () => {
    if (optionsAndScores.length === 0)
      return toastify("error", "Plase add options");
    if (props.selectedOption === "boolean" && optionsAndScores.length !== 2)
      return toastify(
        "error",
        "You have to enter must two options against this selected option type"
      );
    if (allQuestionDataForMatrix) {
      dispatch(
        getMatrixOfQuestion(allQuestionDataForMatrix, callBackForQuestionMatrix)
      );
    }
  };
  const callBackForQuestionMatrix = (res) => {
    props.setMatrixData(res?.data);
    if (res) {
      props.setResByMatrix(true);
    }
  };
  return (
    <>
      {props.selectedOption ? (
        <div className="add-value-main-container">
          <div className="selectedOption">
            <h5>
              {props.selectedOption === "boolean"
                ? "Boolean"
                : props.selectedOption === "numbers"
                ? "Numbers"
                : props.selectedOption === "fields"
                ? "Fields"
                : // : props.selectedOption  === "Radio Buttons"
                // ? "radioButtions"
                props.selectedOption === "checkBoxes"
                ? "Check Boxes"
                : ""}
            </h5>
          </div>
          {props.resByMatrix ? (
            <>
              <div className="options-modal-main-container">
                {
                  // coachClientsData?.data?.length !== 0
                  // optionsAndScores?.data ? (
                  <>
                    <table>
                      <thead>
                        <tr
                          className="header-row"
                          style={{ margin: "10px", height: "50px" }}
                        >
                          <th
                            style={{
                              width: "400px",
                              textAlign: "center",
                            }}
                          >
                            Option values
                          </th>
                          <th
                            style={{
                              width: "400px",
                              textAlign: "center",
                            }}
                          >
                            Scores
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {optionsAndScores?.map((data, index) => {
                          return (
                            <tr
                              className="whole-row-client"
                              key={index}
                              style={{
                                borderBottom: "1px solid #f1f5f9",
                              }}
                            >
                              <td>
                                <div
                                  className="d-grid gap-2 d-md-block"
                                  style={{
                                    wordBreak: "break-word",
                                    textAlign: "center",
                                  }}
                                >
                                  {data?.value}
                                </div>
                              </td>

                              <td
                                style={{
                                  color: "#1b46b4",
                                  width: "160px",
                                  wordBreak: "break-word",
                                  textAlign: "center",
                                }}
                              >
                                {data?.score}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                  // ) : (
                  //   <div className="mt-5">
                  //     <h3>No Questions yet</h3>
                  //   </div>
                  // )
                }
              </div>
            </>
          ) : (
            <>
              <div className="options-container">
                {optionsAndScores.map((data) => {
                  return (
                    <>
                      <p>
                        Option:{data?.value} Score :{data?.score}
                        <Button
                          className="ml-2"
                          size="sm"
                          variant="outline-danger"
                          onClick={() => onOptionClick(data?.id)}
                        >
                          X
                        </Button>
                      </p>
                    </>
                  );
                })}
              </div>
              <div className=" value-and-scoring-inputs">
                <input
                  className="valueAndScoreSelectorField"
                  placeholder="Enter value"
                  onChange={(event) => setValue(event.target.value)}
                  value={value}
                />
                <input
                  type="number"
                  className="valueAndScoreSelectorField"
                  placeholder="Enter score"
                  onChange={(event) => setScore(event.target.value)}
                  value={score}
                />

                <Button
                  className="ml-3 mb-1"
                  size="sm"
                  variant="outline-primary"
                  onClick={onSaveValueAndScore}
                >
                  +
                </Button>

                <Button
                  className="ml-3"
                  variant="primary"
                  onClick={onSaveMatrixData}
                >
                  done
                </Button>
              </div>
            </>
          )}
        </div>
      ) : null}
      <ToastContainer />
    </>
  );
};

export default AddValue;
