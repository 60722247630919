import * as actionTypes from "../actions/actionType";

const initialState = {
  allOrganizations: [],
  blockOrg: {},
  blockOrganizationslist: [],
  updateOrg:{},
};

export const manageOrganizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ALL_ORGANIZATIONS:
      return {
        ...state,
        allOrganizations: action.payload,
      };
    case actionTypes.BLOCK_ORGANIZATION:
      return {
        ...state,
        blockOrg: action.payload,
      };
    case actionTypes.BLOCK_ORGANIZATIONS_LIST:
      return {
        ...state,
        blockOrganizationslist: action.payload,
      };
    case actionTypes.UNBLOCK_ORGANIZATION:
      return {
        ...state,
        unblockOrg: action.payload,
      };
    case actionTypes.UPDATE_ORGANIZATION:
      return {
        ...state,
        updateOrg: action.payload,
      };
    default:
      return state;
  }
};
