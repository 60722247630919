import React, { useEffect, useState } from "react";
import { Calendar, dateFnsLocalizer, Navigate } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { ToastContainer } from "react-toastify";

import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { Dropdown, NavLink } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../redux/actions/authAction";
import { useNavigate } from "react-router-dom";
const ManageActivities = () => {
  const [allEvents, setAllEvents] = useState("");
  const { allUsers } = useSelector((state) => state.getManageUsersData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getAllUsers());
  }, []);
  const locales = {
    "en-US": require("date-fns/locale/en-US"),
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });
  const callBack = (response) => {
    const eventsData = response?.map((item) => {
      // item.start = new Date(item.startDate);
      // item.end = new Date(item.endDate);
      item.start = item.startDate;
      item.end = item.endDate;
      return item;
    });

    setAllEvents(eventsData);
  };
  const onClientWholeRow = (e) => {
    navigate("/Manage-activities", { state: { e } });
  };
  return (
    <>
      <>
        <ToastContainer />
      </>
      <div className="health-kit py-5 px-5">
        <div className="row mb-3">
          <div className="col-md-6">
            <h1 className="healthKitUpdateHeaderFont d-flex ">
              Manage Activities
            </h1>
          </div>
          <div className="col-md-6 d-flex justify-content-end"></div>
        </div>

        <div className="row">
          <>
            <div className="coach-clients-page">
              <>
                <div className="clients-page-main-container">
                  {
                    // coachClientsData?.data?.length !== 0
                    allUsers?.data?.length ? (
                      <>
                        <table>
                          <thead>
                            <tr
                              className="header-row"
                              style={{ margin: "10px", height: "50px" }}
                            >
                              {/* <th
                                style={{
                                  width: "50px",
                                  borderTopLeftRadius: "8px",
                                  borderBottomLeftRadius: "8px",
                                }}
                              ></th> */}
                              <th
                                style={{
                                  width: "400px",
                                  paddingLeft: "6px",
                                }}
                              >
                                Users name
                              </th>
                              <th
                                style={{
                                  width: "400px",
                                }}
                              >
                                Assigned coach
                              </th>
                              <th
                                style={{
                                  width: "400px",
                                }}
                              >
                                Phone number
                              </th>
                              <th
                                style={{
                                  width: "400px",
                                }}
                              >
                                Email
                              </th>
                              {/* <th
                                style={{
                                  width: "400px",
                                }}
                              >
                                Bonus points
                              </th> */}
                              <th
                                style={{
                                  borderTopRightRadius: "8px",
                                  borderBottomRightRadius: "8px",
                                }}
                              ></th>
                            </tr>
                          </thead>

                          <tbody>
                            {allUsers?.data?.map((data, index) => {
                              return (
                                <tr
                                  className="whole-row-manage-activity-user-list"
                                  key={index}
                                  style={{
                                    borderBottom: "1px solid #f1f5f9",
                                  }}
                                  onClick={(e) => onClientWholeRow(data)}
                                >
                                  <td className="pic-name-in-table">
                                    <div className="client-img-in-table ms-3 pt-1">
                                      {data?.imageUrl ? (
                                        <img
                                          src={data?.imageUrl}
                                          className="coach-clients-data-img "
                                          alt="EQWell logo"
                                        />
                                      ) : (
                                        <img
                                          src="/img/sample_img_2.png"
                                          className="coach-clients-data-img "
                                          alt="EQWell logo"
                                        />
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        paddingTop: "15px",
                                        wordBreak: "break-word",
                                      }}
                                    >
                                      {data?.firstName} {data?.lastName}
                                    </div>
                                  </td>

                                  <td>
                                    <div
                                      className="d-grid gap-2 d-md-block"
                                      style={{
                                        wordBreak: "break-word",
                                      }}
                                    >
                                      {data?.coach ? (
                                        <div>
                                          {data.coach?.firstName}{" "}
                                          {data.coach?.lastName}
                                        </div>
                                      ) : (
                                        "Not Assign"
                                      )}
                                    </div>
                                  </td>

                                  <td
                                    style={{
                                      paddingLeft: "12px",
                                      color: "#1b46b4",
                                      width: "160px",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {data?.phoneNumber}
                                  </td>
                                  <td
                                    style={{
                                      paddingLeft: "12px",
                                      color: "#1b46b4",
                                      width: "500px",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {data?.email}
                                  </td>
                                  {/* <td
                                    style={{
                                      paddingLeft: "12px",
                                      color: "#1b46b4",
                                      width: "100px",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {data?.bonusPoints
                                      ? data?.bonusPoints
                                      : "0"}
                                  </td> */}
                                  <td
                                    style={{
                                      width: "133px",
                                    }}
                                  >
                                    {/* <div className="clients-data-btn">
                                      <Dropdown>
                                        <Dropdown.Toggle id="toggle-btn-clients">
                                          <img
                                            src="/img/client-detail-btn.png"
                                            alt="btn-img"
                                            style={{ backgroundColor: "white" }}
                                          />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-options-container">
                                          <Dropdown.Item className="dropdown-options">
                                            <NavLink
                                              className="create-activity-font"
                                              to="/coach-activities"
                                              // onClick={() => {
                                              //   onEditUserModalShow(data);
                                              // }}
                                            >
                                              Edit
                                            </NavLink>
                                            <NavLink
                                              className="create-activity-font"
                                              to="/coach-activities"
                                              // onClick={() => {
                                              //   blockUserHandler(data);
                                              // }}
                                            >
                                              Block
                                            </NavLink>
                                            <NavLink
                                              className="create-activity-font"
                                              to="/coach-activities"
                                              // onClick={() => {
                                              //   onAssignCoach(data);
                                              // }}
                                            >
                                              Assign a coach
                                            </NavLink>
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div> */}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <div className="No-data-statement">
                        {" "}
                        <h3>No user yet</h3>
                      </div>
                    )
                  }
                </div>
              </>
            </div>
          </>
          {/* <div className="calendar">
          <Calendar
            localizer={localizer}
            events={allEvents}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 670, margin: "30px" }}
            // onSelectEvent={handleSelectEvent}
            // views={views}
          />
        </div> */}
        </div>
      </div>
    </>
  );
};

export default ManageActivities;
