import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCoaches,
  getAllOrganizations,
  getAllQuestions,
  getAllUsers,
  getDashboardData,
} from "../../redux/actions/authAction";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { dashboardData } = useSelector((state) => state.allDashboardData);
  useEffect(() => {
    dispatch(getDashboardData());
    dispatch(getAllUsers());
    dispatch(getAllCoaches());
    dispatch(getAllOrganizations());
    dispatch(getAllQuestions());
  }, []);
  return (
    <>
      <div className="health-kit py-5 px-5">
        <div className="row mb-3">
          <div className="col-md-6">
            <h1 className="healthKitUpdateHeaderFont d-flex">Dashboard</h1>
          </div>
          <div className="col-md-6 d-flex justify-content-end"></div>
        </div>

        <div className="row">
          <div className="col-md-3 pt-3 weight">
            <Card>
              <div className="p-3">
                <div className="cards-main-container">
                  {/* <img src="/img/weight.png" alt="img" /> */}
                  <h5 className="healthKitUpdateCardsFont font-weight-bold mt-2">
                    Total Users
                  </h5>
                  <h1>{dashboardData?.data?.totalUsers}</h1>
                </div>

                <div className="position-absolute ">
                  <h4 className="user-weight">
                    {/* {userdata?.data?.weight?.toString().split(".")[0]}
                    <span>{userdata?.data?.weight ? "Kg" : ""}</span> */}
                  </h4>
                  {/* <p>Normal</p> */}
                </div>
              </div>
            </Card>
          </div>
          <div className="col-md-3 pt-3 weight">
            <Card>
              <div className="p-3">
                <div className="cards-main-container">
                  {/* <img src="/img/weight.png" alt="img" /> */}
                  <h5 className="healthKitUpdateCardsFont font-weight-bold mt-2">
                    Survey Submitted
                  </h5>
                  <h1>{dashboardData?.data?.surveySubmittedByUsers}</h1>
                </div>

                <div className="position-absolute ">
                  <h4 className="user-weight">
                    {/* {userdata?.data?.weight?.toString().split(".")[0]}
                    <span>{userdata?.data?.weight ? "Kg" : ""}</span> */}
                  </h4>
                  {/* <p>Normal</p> */}
                </div>
              </div>
            </Card>
          </div>
          <div className="col-md-3 pt-3 weight">
            <Card>
              <div className="p-3">
                <div className="cards-main-container">
                  {/* <img src="/img/weight.png" alt="img" /> */}
                  <h5 className="healthKitUpdateCardsFont font-weight-bold mt-2">
                    Users Taken Survey in Last 30 Days
                  </h5>
                  <h1>
                    {dashboardData?.data?.surveySubmittedByUsersInLastMonth ||
                      0}
                  </h1>
                </div>

                <div className="position-absolute ">
                  <h4 className="user-weight">
                    {/* {userdata?.data?.weight?.toString().split(".")[0]}
                    <span>{userdata?.data?.weight ? "Kg" : ""}</span> */}
                  </h4>
                  {/* <p>Normal</p> */}
                </div>
              </div>
            </Card>
          </div>
          <div className="col-md-3 pt-3 weight">
            <Card>
              <div className="p-3">
                <div className="cards-main-container">
                  {/* <img src="/img/weight.png" alt="img" /> */}
                  <h5 className="healthKitUpdateCardsFont font-weight-bold mt-2">
                    Total Coaches
                  </h5>
                  <h1>{dashboardData?.data?.totalCoaches}</h1>
                </div>

                <div className="position-absolute ">
                  <h4 className="user-weight">
                    {/* {userdata?.data?.weight?.toString().split(".")[0]}
                    <span>{userdata?.data?.weight ? "Kg" : ""}</span> */}
                  </h4>
                  {/* <p>Normal</p> */}
                </div>
              </div>
            </Card>
          </div>
          <div className="col-md-3 pt-3 weight">
            <Card>
              <div className="p-3">
                <div className="cards-main-container">
                  {/* <img src="/img/weight.png" alt="img" /> */}
                  <h5 className="healthKitUpdateCardsFont font-weight-bold mt-2">
                    Total Organization
                  </h5>
                  <h1>{dashboardData?.data?.totalOrganizations}</h1>
                </div>

                <div className="position-absolute ">
                  <h4 className="user-weight">
                    {/* {userdata?.data?.weight?.toString().split(".")[0]}
                    <span>{userdata?.data?.weight ? "Kg" : ""}</span> */}
                  </h4>
                  {/* <p>Normal</p> */}
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
