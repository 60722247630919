import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, Form, Modal, NavLink } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { toastify } from "../../components/Toast";
import {
  addPlan,
  deletePlan,
  getAllGoals,
  getAllOrganizations,
  getFilteredPlans,
  updateplan,
} from "../../redux/actions/authAction";
import Select from "react-select";

const ManagePlans = () => {
  const dispatch = useDispatch();

  const { allOrganizations } = useSelector(
    (state) => state.getOrganizationData
  );
  const [addPlanModal, setAddPlanModal] = useState("");
  const [newPlanName, setNewPlanName] = useState("");
  const [description, setDescription] = useState("");
  const [newPrice, setNewPrice] = useState("");
  const [newDiscount, setNewDiscount] = useState(0);
  const [selectedGoal, setSelectedGoal] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState([]);
  const [isPaid, setIsPaid] = useState(false);
  //edit
  const [editPlanModal, setEditPlanModal] = useState("");
  const [editPlanName, setEditPlanName] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [editPrice, setEditPrice] = useState("");
  const [editDiscount, setEditDiscount] = useState(0);

  const [editSelectedGoal, setEditSelectedGoal] = useState([]);
  const [editSelectedOrganization, setEditSelectedOrganization] = useState([]);
  const [editSelectedOrganizationName, setEditSelectedOrganizationName] =
    useState("");
  const [editIsPaid, setEditIsPaid] = useState("");
  const [editPlanId, setEditPlanId] = useState("");

  const { filterPlans } = useSelector((state) => state.getPlansdata);
  const { allGoalsData } = useSelector((state) => state.getUploadContentData);
  const [field, setField] = useState([]);
  const [organizationFilter, setOrganizationFilter] = useState("");

  useEffect(() => {
    dispatch(getAllOrganizations());
    dispatch(
      getFilteredPlans({
        organizationId: organizationFilter === "all" ? [] : organizationFilter,
      })
    );
    dispatch(getAllGoals());
  }, [organizationFilter]);
  const onAddPlanModalClose = () => {
    setAddPlanModal(false);
    setNewPlanName("");
    setNewPrice("");
    setSelectedGoal("");
    setNewDiscount(0);
    setIsPaid(false);
  };
  const onAddPlanhandler = () => {
    if (newPlanName && Array.isArray(selectedGoal)) {
      const addPlanObj = {
        name: newPlanName,
        goalIds: selectedGoal,
        organizations:
          selectedOrganization === "all" ? [] : selectedOrganization,
        price: newPrice,
        description: description,
        discount: newDiscount,
      };
      dispatch(addPlan(addPlanObj, callBackForAddPlan));
      console.log("selected goals", selectedGoal);
    } else {
      toastify("error", "Please fill all fields ");
    }
  };

  const callBackForAddPlan = () => {
    dispatch(
      getFilteredPlans({
        organizationId: organizationFilter === "all" ? [] : organizationFilter,
      })
    );
    setAddPlanModal(false);
    setNewPlanName("");
    setNewPrice("");
    setSelectedGoal("");
    setIsPaid(false);
    setDescription("");
    setNewDiscount(0);
  };
  const deletePlanHandler = (data) => {
    dispatch(deletePlan(data._id, callbackForDeletePlan));
  };
  const callbackForDeletePlan = () => {
    dispatch(
      getFilteredPlans({
        organizationId: organizationFilter === "all" ? [] : organizationFilter,
      })
    );
  };
  const editPlanHandler = (data) => {
    setEditPlanModal(true);
    setEditPlanId(data._id);
    setEditPlanName(data?.name);
    setEditSelectedGoal(data?.goalIds);

    setEditSelectedOrganization(data?.organizations[0]?._id);
    setEditSelectedOrganizationName(
      data?.organizations[0]?.organizationName ?? "All organizations"
    );
    setEditPrice(data?.price);

    setEditDescription(data?.description);
    setEditDiscount(data?.discount);
  };
  const onEditPlanModalClose = () => {
    setEditPlanModal(false);
    setIsPaid(false);
  };
  const onEditPlanSave = () => {
    console.log("23423423", editSelectedOrganization);
    const editPlanObj = {
      name: editPlanName,
      goalIds: editSelectedGoal,
      organizations:
        editSelectedOrganization === "all" ? [] : editSelectedOrganization,
      price: editPrice,
      description: editDescription,
      discount: editDiscount,
    };
    console.log(editPlanObj);
    dispatch(updateplan(editPlanId, editPlanObj, callBackForEditPlan));
  };
  const callBackForEditPlan = () => {
    dispatch(
      getFilteredPlans({
        organizationId: organizationFilter === "all" ? [] : organizationFilter,
      })
    );
    setEditPlanModal(false);
  };
  const handleEditGoalSelection = (selectedOptions) => {
    const selectedGoalIds = selectedOptions.map((option) => option.value);
    console.log(selectedGoalIds);
    setEditSelectedGoal(selectedGoalIds);
  };

  return (
    <>
      <>
        <ToastContainer />
      </>
      {/* ADD PLAN MODAL */}
      <>
        <Modal
          backdrop="static"
          className="p-0"
          keyboard={false}
          data-dismiss="modal"
          show={addPlanModal}
          onHide={onAddPlanModalClose}
          centered
        >
          <Modal.Header className="modal-header-workout" closeButton>
            <Modal.Title>Add new resource</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <Form>
                <div className="goal-for-plan mb-3">
                  <Form.Label className="d-flex ">Organizations</Form.Label>
                  <Form.Select
                    onChange={(e) => setSelectedOrganization(e.target.value)}
                    value={selectedOrganization}
                    aria-label="Default select example"
                  >
                    {/* <option hidden>Select organization</option> */}
                    <option value="all">All organizations</option>
                    {allOrganizations?.data?.map((organization, index) => {
                      return (
                        <option value={organization._id}>
                          {organization?.organizationName}
                        </option>
                      );
                    })}
                  </Form.Select>
                </div>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    onChange={(e) => setNewPlanName(e.target.value)}
                    value={newPlanName}
                  />
                </Form.Group>

                {/* <div className="goal-for-plan mb-3">
                  <Form.Label className="d-flex ">Goal</Form.Label>
                  <Form.Select
                    onChange={(e) => setSelectedGoal(e.target.value)}
                    value={selectedGoal}
                    aria-label="Default select example"
                  >
                    <option hidden>Select goalsssss</option>
                    {allGoalsData?.data?.map((goal, index) => {
                      return <option value={goal._id}>{goal?.title}</option>;
                    })}
                  </Form.Select>
                </div> */}

                <div className="goal-for-plan mb-3">
                  <label className="d-flex ">Goal</label>
                  <Select
                    isMulti
                    options={allGoalsData?.data?.map((goal) => ({
                      value: goal?._id,
                      label: goal?.title,
                    }))}
                    onChange={(selectedOptions) => {
                      const selectedGoalIds = selectedOptions.map(
                        (option) => option.value
                      );
                      console.log("erefrefrrgrt", selectedGoalIds);
                      setSelectedGoal(selectedGoalIds);
                    }}
                    value={
                      selectedGoal &&
                      selectedGoal.map((goalId) => ({
                        value: goalId,
                        label:
                          allGoalsData?.data?.find(
                            (goal) => goal._id === goalId
                          )?.title || "",
                      }))
                    }
                  />
                </div>

                <Form.Group
                  className="mb-3 mt-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Price $</Form.Label>
                  <Form.Control
                    type="number"
                    min={0}
                    placeholder="Set price in $"
                    onChange={(e) => setNewPrice(e.target.value)}
                    value={newPrice}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3 mt-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Discount %</Form.Label>
                  <Form.Control
                    type="number"
                    min={0}
                    placeholder="set discount"
                    onChange={(e) => setNewDiscount(e.target.value)}
                    value={newDiscount}
                  />
                </Form.Group>

                {newDiscount > 0 && (
                  <p>
                    Price After Discount:{" "}
                    {(newPrice - newPrice * (newDiscount / 100))?.toFixed(2)}
                  </p>
                )}

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Write a description for your resource"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  />
                </Form.Group>
              </Form>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onAddPlanModalClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onAddPlanhandler}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
      {/* EDIT PLAN MODAL */}
      <>
        <Modal
          backdrop="static"
          className="p-0"
          keyboard={false}
          data-dismiss="modal"
          show={editPlanModal}
          onHide={onEditPlanModalClose}
          centered
        >
          <Modal.Header className="modal-header-workout" closeButton>
            <Modal.Title>Edit Resource</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <Form>
                <div className="goal-for-plan mb-3">
                  <Form.Label>Organization</Form.Label>
                  <Form.Select
                    onChange={(e) =>
                      setEditSelectedOrganization(e.target.value)
                    }
                    value={editSelectedOrganization?._id}
                    aria-label="Default select example"
                  >
                    <option selected disabled hidden>
                      {editSelectedOrganizationName}
                    </option>
                    {/* <option value="" disabled>
                      select organization
                    </option> */}
                    <option value="all">All organizations</option>
                    {allOrganizations?.data?.map((organization, index) => {
                      return (
                        <option value={organization._id}>
                          {organization?.organizationName}
                        </option>
                      );
                    })}
                  </Form.Select>
                </div>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="name"
                    onChange={(e) => setEditPlanName(e.target.value)}
                    value={editPlanName}
                  />
                </Form.Group>

                {/* <div className="goal-for-plan mb-3">
                  <Form.Label>Goal</Form.Label>
                  <Form.Select
                    onChange={(e) => setEditSelectedGoal(e.target.value)}
                    value={editSelectedGoal}
                    aria-label="Default select example"
                  >
                    <option hidden>select goal</option>
                    {allGoalsData?.data?.map((goals, index) => {
                      return <option value={goals._id}>{goals?.title}</option>;
                    })}
                  </Form.Select>
                </div> */}

                <div className="goal-for-plan mb-3">
                  <Form.Label>Goal</Form.Label>
                  <Select
                    isMulti
                    onChange={handleEditGoalSelection}
                    options={allGoalsData?.data?.map((goal) => ({
                      value: goal?._id,
                      label: goal?.title,
                    }))}
                    value={
                      editSelectedGoal &&
                      editSelectedGoal.map((goalId) => ({
                        value: goalId,
                        label:
                          allGoalsData?.data?.find(
                            (goal) => goal._id === goalId
                          )?.title || "",
                      }))
                    }
                  />
                </div>

                <Form.Group
                  className="mb-3 mt-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Price $</Form.Label>
                  <Form.Control
                    type="number"
                    min={0}
                    placeholder="set price"
                    onChange={(e) => setEditPrice(e.target.value)}
                    value={editPrice}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3 mt-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Discount %</Form.Label>
                  <Form.Control
                    type="number"
                    min={0}
                    placeholder="set discount"
                    onChange={(e) => setEditDiscount(e.target.value)}
                    value={editDiscount}
                  />
                </Form.Group>
                {editDiscount > 0 && (
                  <p>
                    Price After Discount:{" "}
                    {(editPrice - editPrice * (editDiscount / 100))?.toFixed(2)}
                  </p>
                )}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="write a description for your resource"
                    onChange={(e) => setEditDescription(e.target.value)}
                    value={editDescription}
                  />
                </Form.Group>
              </Form>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onEditPlanModalClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onEditPlanSave}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>

      <>
        <div className="manage-user">
          <div className="row mb-3 pt-5 pl-5">
            <div className="col-md-6">
              <h1 className="healthKitUpdateHeaderFont d-flex">
                Manage Resources
              </h1>
            </div>
            <div
              className="col-md-6 d-flex justify-content-end"
              style={{ paddingRight: "65px" }}
            >
              <div>
                <button
                  className="btn-coach me-4"
                  onClick={() => setAddPlanModal(true)}
                >
                  Add Resource
                </button>
              </div>
              {/* <div>
                <button
                  className="btn-coach btn-block me-5"
                  //   onClick={blockedOrganizationHandler}
                >
                  Blocked organization
                </button>
              </div> */}
            </div>
          </div>
          <>
            <div className="goal-for-content d-flex   ps-5">
              <Form.Label className="d-flex ">
                {/* Filter with organization */}
              </Form.Label>
              <Form.Select
                onChange={(e) => setOrganizationFilter(e.target.value)}
                aria-label="Default select example"
                value={organizationFilter}
              >
                <option value="" disabled>
                  Select Organization
                </option>
                <option value="all">All Organizations</option>
                {allOrganizations?.data?.map((organization, index) => {
                  return (
                    <option value={organization?._id}>
                      {organization?.organizationName}
                    </option>
                  );
                })}
              </Form.Select>
              <div className=" ms-2  ">
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={() => setOrganizationFilter("")}
                >
                  Clear
                </button>
              </div>
            </div>

            <div
              className="coach-clients-page "
              style={{ padding: "2% 3% 0% 3%" }}
            >
              <>
                <div className="clients-page-main-container">
                  {
                    // coachClientsData?.data?.length !== 0
                    filterPlans?.data?.length ? (
                      <>
                        <table>
                          <thead>
                            <tr
                              className="header-row"
                              style={{ margin: "10px", height: "50px" }}
                            >
                              <th
                                style={{
                                  textAlign: "left",
                                  width: "600px",
                                }}
                              >
                                Name
                              </th>
                              <th
                                style={{
                                  width: "600px",
                                  textAlign: "left",
                                  paddingLeft: "6px",
                                }}
                              >
                                Goal
                              </th>
                              <th
                                style={{
                                  textAlign: "left",
                                  width: "400px",
                                }}
                              >
                                Price $
                              </th>

                              <th
                                style={{
                                  textAlign: "left",
                                  width: "400px",
                                }}
                              >
                                Discount %
                              </th>

                              <th
                                style={{
                                  textAlign: "left",
                                  width: "400px",
                                }}
                              >
                                Price After Discount
                              </th>
                              <th
                                style={{
                                  textAlign: "left",
                                  width: "400px",
                                }}
                              >
                                Organization
                              </th>
                              <th
                                style={{
                                  textAlign: "left",
                                  width: "400px",
                                }}
                              >
                                Created date
                              </th>

                              <th
                                style={{
                                  borderTopRightRadius: "8px",
                                  borderBottomRightRadius: "8px",
                                }}
                              ></th>
                            </tr>
                          </thead>

                          <tbody>
                            {filterPlans?.data?.map((data, index) => {
                              return (
                                <tr
                                  className="whole-row-client"
                                  key={index}
                                  style={{
                                    borderBottom: "1px solid #f1f5f9",
                                  }}
                                >
                                  <td
                                    style={{
                                      textAlign: "left",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {data?.name}
                                  </td>

                                  <td>
                                    <div
                                      className="d-grid gap-2 d-md-block"
                                      style={{
                                        textAlign: "left",
                                        wordBreak: "break-word",
                                      }}
                                    >
                                      {/* {data?.goalIds?.title} */}
                                      {data?.goalIds?.map(
                                        (goalId, goalIndex) => (
                                          <div key={goalIndex}>
                                            {
                                              allGoalsData?.data?.find(
                                                (goal) => goal._id === goalId
                                              )?.title
                                            }
                                            {/* {goalIndex < data.goalIds.length - 1 && ",  "} */}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </td>

                                  <td
                                    style={{
                                      paddingLeft: "12px",
                                      color: "#1b46b4",
                                      width: "160px",
                                      textAlign: "left",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {data?.price?.toFixed(2)}
                                  </td>
                                  <td
                                    style={{
                                      paddingLeft: "12px",
                                      color: "#1b46b4",
                                      width: "160px",
                                      textAlign: "left",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {data?.discount?.toFixed(2)}
                                  </td>
                                  <td
                                    style={{
                                      paddingLeft: "12px",
                                      color: "#1b46b4",
                                      width: "160px",
                                      textAlign: "left",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {(
                                      data?.price -
                                      (data?.discount / 100) * data?.price
                                    )?.toFixed(2)}
                                  </td>
                                  <td
                                    style={{
                                      paddingLeft: "12px",
                                      color: "#1b46b4",
                                      width: "500px",
                                      textAlign: "left",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {data?.organizations?.[0]
                                      ?.organizationName || "All organizations"}
                                  </td>
                                  <td
                                    style={{
                                      paddingLeft: "12px",
                                      color: "#1b46b4",
                                      width: "500px",
                                      textAlign: "left",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {moment(data?.createdAt).format("ll")}
                                  </td>
                                  <td
                                    style={{
                                      width: "133px",
                                    }}
                                  >
                                    <div className="clients-data-btn">
                                      <Dropdown>
                                        <Dropdown.Toggle id="toggle-btn-clients">
                                          <img
                                            src="/img/client-detail-btn.png"
                                            alt="btn-img"
                                            style={{ backgroundColor: "white" }}
                                          />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-options-container">
                                          <Dropdown.Item className="dropdown-options">
                                            <NavLink
                                              className="create-activity-font"
                                              onClick={() => {
                                                editPlanHandler(data);
                                              }}
                                            >
                                              Edit
                                            </NavLink>
                                            <NavLink
                                              className="create-activity-font"
                                              onClick={() => {
                                                deletePlanHandler(data);
                                              }}
                                            >
                                              Delete
                                            </NavLink>
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <div className="No-data-statement">
                        <h3>No resources yet</h3>
                      </div>
                    )
                  }
                </div>
              </>
            </div>
          </>
        </div>
      </>
    </>
  );
};

export default ManagePlans;
