// import e from "express";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { toastify } from "../../components/Toast";
import * as authActions from "../../redux/actions/authAction";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  var values = {
    email: email,
    password: password,
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (email && password) {
      setLoading(true);
      dispatch(authActions.userLoginRequest(values, callback));
    } else {
      toastify("error", "Please fill all fields");
    }
  };
  const callback = (e) => {
    setLoading(false);
    if (e.data?.isAdmin === true) {
      navigate("/Dashboard");
    } else {
      toastify("warning", "Invalid credentials");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="login-container">
        <div className="Auth-form-container">
          <>
            <form className="Auth-form" onSubmit={(e) => onSubmitHandler(e)}>
              <div className="Auth-form-content">
                <h3 className="Auth-form-title">Login</h3>

                <div className="form-group login-form  mt-3">
                  <label>Email address</label>
                  <input
                    type="email"
                    value={email}
                    className="form-control mt-1"
                    placeholder="Enter email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group login-form  mt-3">
                  <label>Password</label>
                  <input
                    type="password"
                    value={password}
                    className="form-control mt-1"
                    placeholder="Enter password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="d-grid gap-2 mt-3 mb-2 login">
                  {loading ? (
                    <button className="btn btn-primary">
                      <Spinner
                        className="ps-2"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </form>
          </>
        </div>
      </div>
    </>
  );
};

export default Login;
