import * as actionTypes from "../actions/actionType";

const initialState = {
  allQuestions: [],
  allGoalsData: [],
  addQuestion: [],
  matrixForQuestion: [],
  getQuestion: [],
  deleteQuestion: [],
  getMatrix: [],
  updatedQuestionData: [],
  questionPositionsData:[]
};

export const manageQuestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ALL_QUESTIONS:
      return {
        ...state,
        allQuestions: action.payload,
      };
    case actionTypes.ADD_QUESTION:
      return {
        ...state,
        addQuestion: action.payload,
      };
    case actionTypes.GET_MATRIX:
      return {
        ...state,
        getMatrix: action.payload,
      };
    case actionTypes.GET_QUESTION_BY_ID:
      return {
        ...state,
        getQuestion: action.payload,
      };
    case actionTypes.DELETE_QUESTION:
      return {
        ...state,
        deleteQuestion: action.payload,
      };
    case actionTypes.UPDATE_QUESTION:
      return {
        ...state,
        updatedQuestionData: action.payload,
      };
      case actionTypes.GET_QUESTION_POSITIONS:
        return {
          ...state,
          questionPositionsData: action.payload,
        };
    case actionTypes.ALL_GOALS:
      return {
        ...state,
        allGoalsData: action.payload,
      };
    default:
      return state;
  }
};
