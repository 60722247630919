import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCoaches, getCoachReport } from "../../redux/actions/authAction";
import { Button, Modal, Table } from "react-bootstrap";

const days = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

const CoachReport = () => {
  const dispatch = useDispatch();
  const { coachReportData } = useSelector((state) => state.getReportsData);
  const [state, setState] = useState({
    coachLoginHistoryModal: false,
    singleCoachLoginHistory: [],
  });

  const onCloseCoachLoginHistoryModal = () => {
    setState((prev) => ({ ...prev, coachLoginHistoryModal: false }));
  };

  const showHistoryLogs = (coachLoginLogs) => {
    setState((prev) => ({
      ...prev,
      coachLoginHistoryModal: true,
      singleCoachLoginHistory: coachLoginLogs,
    }));
  };

  useEffect(() => {
    dispatch(getCoachReport());
  }, []);

  return (
    <>
      <div className="manage-user">
        <div className="row mb-3 pt-5 pl-5">
          <div className="col-md-6">
            <h1 className="healthKitUpdateHeaderFont d-flex">Coach's Report</h1>
          </div>
        </div>
        <div className="coach-report-page mt-5">
          <>
            <div className="coach-report-table">
              {coachReportData?.data?.length ? (
                <>
                  <table>
                    <thead>
                      <tr
                        className="header-row"
                        style={{ margin: "10px", height: "50px" }}
                      >
                        <th
                          style={{
                            width: "360px",
                            paddingLeft: "30px",
                            textAlign: "left",
                          }}
                        >
                          Name
                        </th>
                        <th
                          style={{
                            textAlign: "left",
                            width: "300px",
                          }}
                        >
                          Coach engagement rate
                        </th>
                        <th
                          style={{
                            textAlign: "left",
                            width: "300px",
                          }}
                        >
                          Users engagement rate
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            width: "300px",
                          }}
                        >
                          Login history
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {coachReportData?.data?.map((data, index) => {
                        return (
                          <tr
                            className="whole-row-client"
                            key={index}
                            style={{
                              borderBottom: "1px solid #f1f5f9",
                            }}
                          >
                            <td className="pic-name-in-table">
                              <div className="client-img-in-table ms-3 pt-1">
                                {data?.imageUrl ? (
                                  <img
                                    src={data?.imageUrl}
                                    className="coach-clients-data-img "
                                    alt="EQWell logo"
                                  />
                                ) : (
                                  <img
                                    src="/img/sample_img_2.png"
                                    className="coach-clients-data-img "
                                    alt="EQWell logo"
                                  />
                                )}
                              </div>
                              <div
                                style={{
                                  paddingTop: "15px",
                                  textAlign: "left",
                                  wordBreak: "break-word",
                                }}
                              >
                                {data?.firstName} {data?.lastName}
                              </div>
                            </td>

                            <td>
                              <div
                                className="d-grid gap-2 d-md-block"
                                style={{
                                  wordBreak: "break-word",
                                }}
                              >
                                <div style={{ color: "blue" }}>
                                  {
                                    data?.coachEngagement
                                      ?.toString()
                                      .split(".")[0]
                                  }
                                </div>
                              </div>
                            </td>
                            <td>
                              <div
                                className="d-grid gap-2 d-md-block"
                                style={{
                                  textAlign: "left",
                                  wordBreak: "break-word",
                                }}
                              >
                                {/* <div style={{ color: "blue" }}>
                                  {
                                    data?.userEngagements
                                      ?.toString()
                                      .split(".")[0]
                                  }
                                  %
                                </div> */}
                                <div
                                  style={{
                                    color: "blue",
                                    display: "flex",
                                    gap: "15px",
                                  }}
                                >
                                  <p style={{ marginBottom: "0px" }}>
                                    {
                                      data?.coachTotalUserEngagements?.totalSevenDays
                                        ?.toString()
                                        .split(".")[0]
                                    }
                                    %{" "}
                                  </p>

                                  <p style={{ marginBottom: "0px" }}>
                                    {
                                      data?.coachTotalUserEngagements?.totalThirtDays
                                        ?.toString()
                                        .split(".")[0]
                                    }
                                    %
                                  </p>
                                  <p style={{ marginBottom: "0px" }}>
                                    {
                                      data?.coachTotalUserEngagements?.totalNinetyDays
                                        ?.toString()
                                        .split(".")[0]
                                    }
                                    %{" "}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <Button
                                onClick={() => {
                                  showHistoryLogs(data?.coachLoginLogs);
                                }}
                              >
                                Show
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              ) : (
                <div className="mt-5">
                  <h3>No Coaches yet</h3>
                </div>
              )}
            </div>
          </>
        </div>
      </div>

      {/* COACH LOGIN HISTORY MODAL */}
      <Modal
        backdrop="static"
        className="p-0"
        keyboard={false}
        data-dismiss="modal"
        dialogClassName="modal-90w"
        show={state.coachLoginHistoryModal}
        onHide={onCloseCoachLoginHistoryModal}
        centered
        size="md"
      >
        <Modal.Header className="modal-header-workout">
          <Modal.Title>Coach Login History</Modal.Title>
        </Modal.Header>
        <div className="coach-login-history-body">
          {" "}
          <Modal.Body>
            {state?.singleCoachLoginHistory?.length === 0 ? (
              <h5>This user has not been logged in yet.</h5>
            ) : (
              <>
                <Table bordered={false} borderless>
                  <thead>
                    <tr>
                      <td>
                        <h5>Date</h5>
                      </td>
                      <td>
                        <h5>Time</h5>
                      </td>
                    </tr>
                  </thead>

                  <tbody>
                    {state?.singleCoachLoginHistory.map((coachLog, idx) => (
                      <>
                        <tr>
                          <td>
                            <p>{new Date(coachLog?.loginAt).toDateString()}</p>
                          </td>
                          <td>
                            <p>
                              {new Date(coachLog?.loginAt).getHours()}:
                              {new Date(coachLog?.loginAt).getMinutes()}:
                              {new Date(coachLog?.loginAt).getSeconds()}
                            </p>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </Table>
                {/* <div className="d-flex align-items-center justify-content-between">
                <h5>Date</h5>
                <h5>Day</h5>
                <h5>Time</h5>
              </div> */}

                {/* {state.singleCoachLoginHistory.map((coachLog, idx) => (
                <div className="d-flex align-items-center justify-content-between">
                  <p>{new Date(coachLog.loginAt).toUTCString()}</p>
                  <p>{new Date(coachLog.loginAt).toUTCString()}</p>
                  <p>{new Date(coachLog.loginAt).toUTCString()}</p>
                </div>
              ))} */}
              </>
            )}
          </Modal.Body>
        </div>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCloseCoachLoginHistoryModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CoachReport;
