import * as actionTypes from "../actions/actionType";

const initialState = {
  uploadFileData: [],
  uploadContentData: {},
  DailyContentData: [],
  allGoalsData: [],
  contentList: [],
  planDayListData: [],
  selectedContentData: [],
};

export const manageUploadContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_FILE:
      return {
        ...state,
        uploadFileData: action.payload,
      };
    case actionTypes.UPLOAD_CONTENT:
      return {
        ...state,
        uploadContentData: action.payload,
      };
    case actionTypes.PLAN_DAY_LIST:
      return {
        ...state,
        planDayListData: action.payload,
      };
    case actionTypes.GET_CONTENT:
      return {
        ...state,
        selectedContentData: action.payload,
      };
    case actionTypes.DAILY_CONTENT:
      return {
        ...state,
        DailyContentData: action.payload,
      };
    case actionTypes.ALL_GOALS:
      return {
        ...state,
        allGoalsData: action.payload,
      };
    case actionTypes.CONTENTLIST_BY_PLAN:
      return {
        ...state,
        contentList: action.payload,
      };
    default:
      return state;
  }
};
