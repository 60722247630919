import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, Modal, NavLink } from "react-bootstrap";
import { ToastContainer } from "react-toastify";

import DatePicker from "react-date-picker";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getAllCoaches,
  getBlockListOfCoaches,
  blockCoach,
  unBlockCoach,
  addCoach,
  updateCoach,
} from "../../redux/actions/authAction";

const ManageCoaches = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [newFirstName, setNewFirstName] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [coachIdToUpdateCoach, setCoachIdToUpdateCoach] = useState("");

  const [titleState, setTitleState] = useState("");
  const [show, setShow] = useState(false);
  const [editCoachModal, setEditCoachModal] = useState(false);
  const [blockCoachModal, setBlockCoachModal] = useState(false);
  const [resState, setResState] = useState(false);
  const [newMob, setNewMob] = useState("");

  const dispatch = useDispatch();
  const { allCoaches } = useSelector((state) => state.getManageCoachesData);
  const { blockCoachesList } = useSelector(
    (state) => state.getManageCoachesData
  );
  useEffect(() => {
    if (newPhoneNumber.startsWith("92")) {
      setNewMob("+" + newPhoneNumber);
    } else {
      setNewMob("+1" + newPhoneNumber);
    }
  }, [newPhoneNumber]);

  useEffect(() => {
    dispatch(getAllCoaches());
  }, []);
  useEffect(() => {
    dispatch(getBlockListOfCoaches());
  }, [resState]);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const onSaveNewCoachData = () => {
    const newCoachData = {
      firstName: newFirstName,
      lastName: newLastName,
      email: newEmail,
      phoneNumber: newMob,
      isCoach: true,
      isVerified: true,
    };
    dispatch(addCoach(newCoachData, callbackForAddCoach));
  };
  const callbackForAddCoach = () => {
    dispatch(getAllCoaches());
    handleClose();
    setNewEmail("");
    setNewPhoneNumber("");
    setNewLastName("");
    setNewFirstName("");
  };
  const editCoachModalClose = () => {
    setEditCoachModal(false);
  };
  const editCoachModalShow = (data) => {
    setEditCoachModal(true);
    setCoachIdToUpdateCoach(data?._id);
    setFirstName(data?.firstName);
    setLastName(data?.lastName);
    setPhoneNumber(data?.phoneNumber);
    setEmail(data?.email);
  };

  const editCoachDetailsHandler = () => {
    const coachUpdatedData = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
    };
    dispatch(
      updateCoach(
        coachIdToUpdateCoach,
        coachUpdatedData,
        callBackForUpdateCoach
      )
    );
  };
  const callBackForUpdateCoach = () => {
    dispatch(getAllCoaches());
    editCoachModalClose();
  };
  const blockCoachModalClose = () => {
    setBlockCoachModal(false);
  };
  const blockCoachModalShow = () => {
    setBlockCoachModal(true);
  };

  const unblockCoachHandler = (data) => {
    dispatch(unBlockCoach(data._id, callbackForUnBlockCoach));
  };
  const callbackForUnBlockCoach = () => {
    setResState(!resState);
    dispatch(getAllCoaches());
    blockCoachModalClose();
  };
  const blockCoachHandler = (data) => {
    dispatch(blockCoach(data._id, callbackForBlockCoach));
  };
  const callbackForBlockCoach = () => {
    dispatch(getAllCoaches());
    setResState(!resState);
  };

  return (
    <>
      <>
        {/* ADD NEW COACH MODAL */}
        <>
          <Modal
            backdrop="static"
            className="p-0"
            keyboard={false}
            data-dismiss="modal"
            show={show}
            onHide={handleClose}
            centered
          >
            <Modal.Header className="modal-header-workout" closeButton>
              <Modal.Title>Add new Coach</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="first name"
                      onChange={(e) => setNewFirstName(e.target.value)}
                      value={newFirstName}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="last name"
                      onChange={(e) => setNewLastName(e.target.value)}
                      value={newLastName}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>phone Number </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="phone number"
                      onChange={(e) => setNewPhoneNumber(e.target.value)}
                      value={newPhoneNumber}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="email"
                      onChange={(e) => setNewEmail(e.target.value)}
                      value={newEmail}
                    />
                  </Form.Group>
                </Form>
              </>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={onSaveNewCoachData}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </>
        {/* EDIT COACH MODAL */}
        <>
          <Modal
            backdrop="static"
            className="p-0"
            keyboard={false}
            data-dismiss="modal"
            show={editCoachModal}
            onHide={editCoachModalClose}
            centered
          >
            <Modal.Header className="modal-header-workout" closeButton>
              <Modal.Title>Edit Coach</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <div className="user-pic-name">
      <div className="edit-profile-img">
        {showImage !== null ? (
          <img
            className=" user-profile-img"
            src={showImage}
            alt="img1"
          />
        ) : (
          <>
            {userprofileData?.data?.imageUrl ? (
              <img
                className="user-profile-img"
                src={userprofileData?.data?.imageUrl}
                alt="img"
              />
            ) : (
              <img
                className="user-profile-img"
                src="/img/sample_img_2.png"
                alt="img"
              />
            )}
          </>
        )}

        <div className="image-file">
          <label htmlFor="file-input">
            <img
              className="edit-profile-img-icon"
              src="/img/edit.png"
              alt="img"
            />
          </label>
          <input
            type="file"
            id="file-input"
            onChange={onImageSelect}
          />
        </div>
      </div>
    </div> */}
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>First name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="First name"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Last name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Last name"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>phone Number </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Phone number"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    value={phoneNumber}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={editCoachModalClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={editCoachDetailsHandler}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </>
        {/* BLOCK COACH LIST MODAL */}
        <>
          <Modal
            backdrop="static"
            className="p-0"
            size="lg"
            keyboard={false}
            data-dismiss="modal"
            show={blockCoachModal}
            onHide={blockCoachModalClose}
            centered
          >
            <Modal.Header className="modal-header-workout" closeButton>
              <Modal.Title>Blocked list of coaches</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body-unblock-list">
              <>
                {
                  // coachClientsData?.data?.length !== 0
                  blockCoachesList?.data?.length ? (
                    <>
                      <table>
                        <thead>
                          <tr
                            className="header-row"
                            style={{ margin: "10px", height: "50px" }}
                          >
                            <th
                              style={{
                                width: "400px",
                                paddingLeft: "6px",
                                borderTopLeftRadius: "8px",
                                borderBottomLeftRadius: "8px",
                              }}
                            >
                              Coaches name
                            </th>

                            <th
                              className="block-user-email"
                              style={{
                                width: "400px",
                              }}
                            >
                              Email
                            </th>
                            <th
                              style={{
                                borderTopRightRadius: "8px",
                                borderBottomRightRadius: "8px",
                              }}
                            ></th>
                          </tr>
                        </thead>

                        <tbody>
                          {blockCoachesList?.data?.map((data, index) => {
                            return (
                              <tr
                                className="whole-row-client"
                                key={index}
                                style={{
                                  borderBottom: "1px solid #f1f5f9",
                                }}
                              >
                                <td className="pic-name-in-table">
                                  <div className="client-img-in-table ms-3 pt-1">
                                    <img
                                      src={
                                        data?.imageUrl
                                          ? data?.imageUrl
                                          : "/img/sample_img_2.png"
                                      }
                                      className="coach-clients-data-img "
                                      alt="EQWell logo"
                                    />
                                    {/* )} */}
                                  </div>
                                  <div
                                    style={{
                                      paddingTop: "15px",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {data?.firstName} {data?.lastName}
                                  </div>
                                </td>

                                <td
                                  style={{
                                    color: "#1b46b4",
                                    width: "500px",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {data?.email}
                                </td>
                                <td
                                  style={{
                                    width: "133px",
                                  }}
                                >
                                  <div className="clients-data-btn">
                                    <button
                                      className="btn-unblock-user"
                                      onClick={() => unblockCoachHandler(data)}
                                    >
                                      Unblock
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <h5>No coaches blocked yet.</h5>
                  )
                }
              </>
            </Modal.Body>
          </Modal>
        </>
        <>
          <div className="manage-user">
            <div className="row mb-3 pt-5 pl-5">
              <div className="col-md-6">
                <h1 className="healthKitUpdateHeaderFont d-flex">
                  Manage Coaches
                </h1>
              </div>
              <div
                className="col-md-6 d-flex justify-content-end"
                style={{ paddingRight: "65px" }}
              >
                <div>
                  {/* <button className="btn-srvc">Add an event</button> */}
                  {/* <NavLink to="/chat-coach"> */}
                  <button className="btn-coach me-4" onClick={handleShow}>
                    Add new coach
                  </button>
                  {/* </NavLink> */}
                </div>
                <div>
                  <button
                    className="btn-coach btn-block me-5"
                    onClick={blockCoachModalShow}
                  >
                    Blocked Coaches
                  </button>
                </div>
              </div>
            </div>
            <>
              <div
                className="coach-clients-page "
                style={{ padding: "2% 3% 0% 3%" }}
              >
                <>
                  <div className="clients-page-main-container">
                    {
                      // coachClientsData?.data?.length !== 0
                      allCoaches?.data?.length ? (
                        <>
                          <table>
                            <thead>
                              <tr
                                className="header-row"
                                style={{ margin: "10px", height: "50px" }}
                              >
                                <th
                                  style={{
                                    width: "350px",
                                    paddingLeft: "26px",
                                    textAlign: "left",
                                  }}
                                >
                                  Coach name
                                </th>
                                <th
                                  style={{
                                    width: "300px",
                                    textAlign: "left",
                                  }}
                                >
                                  Email
                                </th>
                                <th
                                  style={{
                                    width: "275px",
                                    textAlign: "left",
                                  }}
                                >
                                  Phone Number
                                </th>
                                <th
                                  style={{
                                    width: "150px",
                                    textAlign: "left",
                                  }}
                                >
                                  Total Clients
                                </th>
                                <th
                                  style={{
                                    borderTopRightRadius: "8px",
                                    borderBottomRightRadius: "8px",
                                  }}
                                ></th>
                              </tr>
                            </thead>

                            <tbody>
                              {allCoaches?.data?.map((data, index) => {
                                return (
                                  <tr
                                    className="whole-row-client"
                                    key={index}
                                    style={{
                                      borderBottom: "1px solid #f1f5f9",
                                    }}
                                  >
                                    <td className="pic-name-in-table">
                                      <div className="client-img-in-table ms-3 pt-1">
                                        <img
                                          src={
                                            data?.imageUrl
                                              ? data?.imageUrl
                                              : "/img/sample_img_2.png"
                                          }
                                          className="coach-clients-data-img "
                                          alt="EQWell logo"
                                        />
                                      </div>
                                      <div style={{ paddingTop: "15px" }}>
                                        {data?.firstName} {data?.lastName}
                                      </div>
                                    </td>

                                    <td>
                                      <div
                                        className="d-grid gap-2 d-md-block"
                                        style={{ textAlign: "left" }}
                                      >
                                        {data?.email}
                                      </div>
                                    </td>

                                    <td
                                      style={{
                                        paddingLeft: "12px",
                                        color: "#1b46b4",
                                        textAlign: "left",
                                      }}
                                    >
                                      {data?.phoneNumber}
                                    </td>

                                    <td
                                      style={{
                                        paddingLeft: "12px",
                                        color: "#1b46b4",
                                        textAlign: "left",
                                      }}
                                    >
                                      {data?.clients.length}
                                    </td>

                                    <td
                                      style={{
                                        width: "133px",
                                      }}
                                    >
                                      <div className="clients-data-btn">
                                        <Dropdown>
                                          <Dropdown.Toggle id="toggle-btn-clients">
                                            <img
                                              src="/img/client-detail-btn.png"
                                              alt="btn-img"
                                              style={{
                                                backgroundColor: "white",
                                              }}
                                            />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu className="dropdown-options-container">
                                            <Dropdown.Item className="dropdown-options">
                                              <NavLink
                                                className="create-activity-font"
                                                to="/coach-activities"
                                                onClick={() => {
                                                  editCoachModalShow(data);
                                                }}
                                              >
                                                Edit
                                              </NavLink>
                                              <NavLink
                                                className="create-activity-font"
                                                to="/coach-activities"
                                                onClick={() => {
                                                  blockCoachHandler(data);
                                                }}
                                              >
                                                Block
                                              </NavLink>
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}{" "}
                            </tbody>
                          </table>
                        </>
                      ) : (
                        <div className="No-data-statement">
                          {" "}
                          <h3>No coaches yet</h3>
                        </div>
                      )
                    }
                  </div>
                </>
              </div>
            </>
          </div>
        </>
      </>
      <ToastContainer />
    </>
  );
};

export default ManageCoaches;
