import * as actionTypes from "../actions/actionType";
const initialState = {
  organizationReportData: [],
  coachReportData: [],
};

export const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ORGANIZATION_REPORT:
      return {
        ...state,
        organizationReportData: action.payload,
      };
    case actionTypes.GET_COACH_REPORT:
      return {
        ...state,
        coachReportData: action.payload,
      };
    default:
      return state;
  }
};
