import * as actionTypes from "../actions/actionType";

const initialState = {
  allActivities: [],
  selectedActivityDataOfUser: [],
  updateActivityInReducer: [],
  selectedUserGoals: [],
  createActivityInReducer: "",
  DeleteActivityInReducer: [],
};

export const manageActivityReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_USER_ACTIVITES:
      return {
        ...state,
        selectedUserActivities: action.payload,
        token: action.payload.token,
      };
    case actionTypes.GET_SELECTED_ACTIVITY_DATA_OF_USER:
      return {
        ...state,
        selectedActivityDataOfUser: action.payload,
      };

    case actionTypes.CREATE_ACTIVITY:
      return {
        ...state,
        createActivityInReducer: action.payload,
      };
    case actionTypes.GET_USER_GOALS:
      return {
        ...state,
        selectedUserGoals: action.payload,
      };

    case actionTypes.DELETE_ACTIVITY:
      return {
        ...state,
        DeleteActivityInReducer: action.payload,
      };
    case actionTypes.UPDATE_ACTIVITY:
      return {
        ...state,
        updateActivityInReducer: action.payload,
      };
    default:
      return state;
  }
};
